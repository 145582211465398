import { useTranslation } from 'react-i18next';

const Test = () => {

    const { t, i18n } = useTranslation();
    const sharableResultPage = window.location.origin;

    const shareToFacebook = () => {
        if( typeof(window.FB)!= 'undefined' ){
          window.FB.ui({
            method: 'share',
            href: sharableResultPage,
            display: 'popup'
          }, function(response){
            console.log('FB share response', response);
          });
        }
    };
    return (
        <div className="h-dvh flex items-center justify-center bg-primary text-white">
            <div className="" onClick={shareToFacebook}>Share to facebook</div>
            <br />
            <br />
            <div className="">
              <a href="https://www.google.com" target='_blank'>new tab</a>
            </div>
        </div>
    )
}

export default Test;