// src/components/Result.js
import React, { useContext, useState } from 'react';
import { QuizContext } from '../context/QuizContext';
import { AppContext } from "../context/AppContext.js";
import { useTranslation, Trans } from 'react-i18next';
import { useNavigate } from "react-router-dom";
import { useLocation } from 'react-router-dom';

const Result = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { state } = useContext(QuizContext);
  const { triggerGTMEvent } = useContext(AppContext);
  const { t, i18n } = useTranslation();
  const [isShowingImage, setIsShowingImage] = useState(false);

  const queryParams = new URLSearchParams(location.search);

  // if( !queryParams.get('type') ){
  //   navigate("/");
  // } 
  // const resultId = queryParams.get('type') || 'efficiency';
  const resultId = state.resultId;
  const resultIndex = state.resultIndex;
  const resultItems = t(`results.items`, { returnObjects: true });
  const resultItem = resultItems.filter( item=>item.id == resultId )[0];

  const resultName = resultItem.name;
  const resultBody = resultItem.body;
  const resultShare = resultItem.share;

  const imgSrc = `/images/result-${resultId}.png`;

  const sharableResultPage = window.location.origin + `/${i18n.language}/results/${resultId}/`;
  const sharableIgImage = window.location.origin + `/images/results/${i18n.language}_result_story_${resultId}.jpg`;


  triggerGTMEvent('result', {
    result: resultId
  })

  const shareFriend = async () => {
    if (navigator.share) {
      navigator.share({
          url: t("app_url")
      })
      .then(() => console.log('Thanks for sharing!'))
      .catch(console.error);
    } else {
        console.error('Sharing is not supported on this browser.');
    }
  };

  const shareToInstagram = async () => {
    showPopup();
  };

  const shareToFacebook = () => {
      if( typeof(window.FB)!= 'undefined' ){
        window.FB.ui({
          method: 'share',
          href: sharableResultPage,
          display: 'popup'
        }, function(response){
          console.log('FB share response', response);
        });
      }
  };

  const showPopup = () => {
    setIsShowingImage(true);
  };
  const hidePopup = () => {
    setIsShowingImage(false);
  };

  return (
    <div className="page page-result" style={{
      backgroundImage: 'url("/images/bg-long.jpg")'
    }}>
      <div className="container">
        <div className="pt-md pb-lg flex flex-col gap-y-md">
          <div className="result-card">
            <div className="text-xxl px-md shadowing">{t("results.heading")}</div>
            <div className="-mt-lg image-container"><img src={imgSrc} alt=""/></div>
            <div className="-mt-xl pb-md px-md relative z-[3]">
              <div className="leading-[1.1em] mb-md result-title h-[60px] rounded-[30px]">{resultName}</div>
              <p>{resultBody}</p>
            </div>
          </div>
          <div>
            <img alt="" className="w-[64px] mx-auto mb-sm" src="/images/result-icon_share.png" />
            {/* {resultShare} */}
            <img src="/images/graphs/share.png" alt="" className="inline w-[20px] -mt-xs mr-xs" />
            <Trans
              i18nKey={resultShare}
              components={{ br: <br /> }}
            />
          </div>
          <div>
            <button onClick={shareToFacebook}><img alt="" className="gtm-result-share-fb w-[64px] mx-auto" src="/images/result-icon_fb-gradient.png" /></button>
            <h2 className="text-md">{t("home.instruction_facebook_title")}</h2>
            <Trans i18nKey="home.instruction_facebook" components={{span: <span />}}/>
            
          </div>
          <div>
            <button onClick={shareToInstagram}><img alt="" className="gtm-result-share-ig w-[64px] mx-auto" src="/images/result-icon_ig-gradient.png" /></button>
            <h2 className="text-md">{t("home.instruction_instagram_title")}</h2>
            <Trans i18nKey="home.instruction_instagram" components={{span: <span />}}/>
            
          </div>
          
          {/* <div className="flex items-center gap-[5px]">
            <span className="grow-1 w-full block h-[1px] bg-white"></span>
            <span className="basis-auto">{t("results.or")}</span>
            <span className="grow-1 w-full block h-[1px] bg-white"></span>
          </div> */}
          {/* <div className="text-md">
            <button className="gtm-result-share-fd" onClick={shareFriend}><img alt="" className="w-[64px] mx-auto" src="/images/result-icon_friends.png" /></button>
            <div>{t("results.shareFriend")}</div>
          </div> */}

          <div className="mt-md bg-white bg-white text-primary p-md rounded-[20px]">
            <p className="mb-md"><Trans i18nKey="results.popupContent" components={{strong: <strong />}}/></p>
            <a className="gold-box h-[40px] rounded-[20px]" href={t("booking_url")} target="_blank">
              <span className="gtm-result-act-now">{t('results.cta')}</span>
            </a>
          </div>

          <div className="mt-lg pb-md">
              <p className="text-xs text-left text-white">
              <Trans i18nKey="home.promotion_license" components={{br: <br />}}/>
              </p>
          </div>
        </div>
      </div>
      
      {isShowingImage && 
        <div className="popup" >
            <div className="popup-overlay" onClick={()=>{hidePopup()}}></div>
            <div className="popup-content">
              <div className="p-sm bg-primary text-white">
                <p className="text-sm">{t('results.longpress')}</p>
              </div>
              <img src={sharableIgImage} alt="" />
            </div>
            <button className="popup-close" onClick={()=>{hidePopup()}}>{t('close')}</button>
        </div>
      }

    </div>
  );
};

export default Result;
